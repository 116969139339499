exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin/[...].tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-complete-tsx": () => import("./../../../src/pages/complete.tsx" /* webpackChunkName: "component---src-pages-complete-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-sample-tsx": () => import("./../../../src/pages/sample.tsx" /* webpackChunkName: "component---src-pages-sample-tsx" */),
  "component---src-pages-submit-tsx": () => import("./../../../src/pages/submit.tsx" /* webpackChunkName: "component---src-pages-submit-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-upload-tsx": () => import("./../../../src/pages/upload/[...].tsx" /* webpackChunkName: "component---src-pages-upload-tsx" */)
}

